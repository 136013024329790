import {
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Switch,
  Modal,
  Dimensions,
} from "react-native";
import { Text, View } from "../../../components/Themed";
import { jsonToCSV } from "react-native-csv";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { RootStackScreenProps } from "../../../types"; // @ts-ignore
import React, { useEffect, useState } from "react";
import Icon from "react-native-vector-icons/AntDesign";
import { useFocusEffect } from "@react-navigation/native";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, postData } from "../../../networking/api";
// import { DataTable } from "react-native-paper";
import { Divider, Provider as PaperProvider } from "react-native-paper";
import IconEdit from "react-native-vector-icons/FontAwesome";
import { TextInput } from "react-native-paper";

import IconEye from "react-native-vector-icons/Entypo";
import { color } from "react-native-elements/dist/helpers";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function DeviceProfile({ navigation }) {
  var idProject = useSelector((state) => state.idUpdating);

  const [text, setText] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  var [counter, setCounter] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [DeviceProfileDataLength, setDeviceProfileDataLength] = useState(0);
  const [DeviceProfileNameData, setDeviceProfileNameData] = useState([]);
  const [deviceListExport, setDeviceListExport] = useState([]);

  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);
  const [isDeviceProfileNameDataValid, setDeviceProfileNameDataValid] =
    useState(false);
  const [isDeviceProfileNameDataEntered, setDeviceProfileNameDataEntered] =
    useState(false);
  const [sort, setsort] = useState(0);
  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");
  const [projectId, setProjectId] = useState("");

  // Datatable device profile column and style

  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const columns = [
    {
      name: "Sr.No",
      selector: "id",
      //cell: (row, index) => index + 1,
      selector: (row) => row.index,

      // sortable: true,
      width: "100px",
    },
    {
      name: "Device ",
      selector: (row) => row.device_name !== null && row.device_name !== undefined ? row.device_name : "",
      sortable: true,
    },
    {
      name: "Omega Name ",
      selector: (row) =>
        row.device_name ,
      sortable: true,
    },

    {
      name: "Zone",
      selector: (row) =>
        row.zone_master == null ? "" : row.zone_master.zone_name,
      sortable: true,
    },
    {
      name: "Subzone",
      selector: (row) =>
        row.sub_zone_master == null ? "" : row.sub_zone_master.sub_zone_name,
      sortable: true,
    },
    // {
    //   name: "Assignment Type",
    //   selector: "assignment_type",
    //   sortable: true,
    // },
    {
      name: "Action",
      // selector: "country",
      width: "100px",

      // sortable: true
      cell: (item) => (
        <TouchableOpacity
          style={{ marginRight: 10 }}
          onPress={() => {
            // console.log("item---edit", item);
            navigation.navigate("EditDeviceProfile", {
              DeviceProfileId: item.id,
            });
          }}
        >
          <IconEdit
            name="pencil"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
      ),
    },
    {
      name: "Status",
      width: "100px",

      cell: (item, index) => (
        <Switch
          trackColor={{ false: "#767577", true: "#1DA1F2" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          activeThumbColor="#fff"
          onValueChange={() => {
            // console.log("toggle index", index);
            setEditPopup(true);
            setpopid(item.id);
            setpopindex(index);
          }}
          value={item.active == 1 ? true : false}
        />
      ),
    },
  ];
  const toggleSwitch = () => {
    var tempIndex = DeviceProfileNameData.findIndex((item) => item.id === popid);

    if (DeviceProfileNameData[tempIndex].active == 1) {
      DeviceProfileNameData[tempIndex].active = 0;
      activeDeviceProfile(popid, 0);
    } else {
      DeviceProfileNameData[tempIndex].active = 1;
      activeDeviceProfile(popid, 1);
    }
    setDeviceProfileNameData(DeviceProfileNameData);
  };
  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, DeviceProfileDataLength);
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;
  React.useEffect(() => {
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      searchFilterFunction("");
    }, [])
  );

  useEffect(() => {
    // console.log("is press device ");
    getAdminData();
  }, [idProject]);
  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue);
    if (projValue != null) {
      // console.log("project id select in schedule ", projValue);
      setProjectId(projValue);
      getAllDeviceProfileData([], projValue);
      getDeviceListForExport(projValue)
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse in zone ==>", userDetailsresponse);
      setProjectId(userDetailsresponse.project_selected);
      // setEmpCode(userDetailsresponse.employee_code);
      getAllDeviceProfileData([], userDetailsresponse.project_selected);
      getDeviceListForExport(userDetailsresponse.project_selected)
    }
  };

  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = DeviceProfileNameData.filter(function (item) {
        const itemData = item.device_name
          ? item.device_name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(DeviceProfileNameData);
      setSearch(text);
      setPage(0);
    }
  };
  //isActive State
  const activeDeviceProfile = async (did, isActive) => {
    setIsLoading(true);
    try {
      var DeviceProfileData = JSON.stringify({
        id: did,
        module: "devices_master",
        data: {
          active: isActive,
        },
      });
      // console.log("deviceprofiledata payload", DeviceProfileData);
      const activeDeviceProfileResponse = await postData(
        DeviceProfileData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeDeviceProfileResponse.code;
      if (code == 1) {
        // //alert(activeDeviceProfileResponse.message);
        setIsVisible(false);
        getAllDeviceProfileData([], projectId);
        getDeviceListForExport(projectId)

      } else {
        setIsLoading(false);
        // //alert(activeDeviceProfileResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const exportCsvDeviceProfileData = async () => {
    setIsLoading(true);
    try {
      var getDeviceProfileData = JSON.stringify({
        module: "devices_master",
      });
      const DeviceProfileNameResponse = await postData(
        getDeviceProfileData,
        "masters/exportFile"
      );
      setIsLoading(false);
      var code = DeviceProfileNameResponse.code;
      if (code == 1) {


        // setDeviceProfileDataLength(DeviceProfileNameResponse.data.length);
        setDeviceProfileNameData(DeviceProfileNameResponse.data);
        // setFilteredDataSource(DeviceProfileNameResponse.data)
        // const results = jsonToCSV(DeviceProfileNameResponse.data);
        // // console.log("csv==", results);
      } else {
        setIsLoading(false);
        //alert(DeviceProfileNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Get all DeviceProfile list Api
  const getAllDeviceProfileData = async (sortdata: never[], pid) => {
    // console.log("pidpidpid", pid);
    setIsLoading(true);
    if (sortdata.length > 0) {
      setDeviceProfileDataLength(sortdata.length);
      setDeviceProfileNameData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var getDeviceProfileData = JSON.stringify({
          active: 1,
          condition: {
            project_id: pid,
          },
          module: "device_profile",
          relation: [
            {
              module: "device_profile_details",
              subModule: "devices_master",
            },
            {
              module: "zone_master",
            },
            {
              module: "sub_zone_master",
            },
          ],
        });
        const DeviceProfileNameResponse = await postData(
          getDeviceProfileData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = DeviceProfileNameResponse.code;
        if (code == 1) {

          setDeviceProfileDataLength(DeviceProfileNameResponse.data.length);
          setDeviceProfileNameData(DeviceProfileNameResponse.data);
          setFilteredDataSource(DeviceProfileNameResponse.data);

          // const results = jsonToCSV(DeviceProfileNameResponse.data);
          // // console.log("csv==", results);
        } else {
          setIsLoading(false);
          //alert(DeviceProfileNameResponse.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };
  const sortFun = () => {
    const sortData = filterData.sort((item, b) =>
      sort == 1
        ? item.device_name.localeCompare(b.device_name)
        : b.device_name.localeCompare(item.device_name)
    );
    getAllDeviceProfileData(sortData);
    setFilteredDataSource(sortData);
  };

  const getDeviceListForExport = async (pid) => {
    setIsLoading(true);
    try {
      var stateData = JSON.stringify({
        modelName: "device_profile",
        project_id: pid,
      });
      const deviceDataResponse = await postData(
        stateData,
        "masters/dependentListExport"
      );
      setIsLoading(false);
      var code = deviceDataResponse.code;
      console.log('deviceDataResponse==>', deviceDataResponse);
      if (code == 1) {
        setDeviceListExport(deviceDataResponse.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const downloadExcelReport = async () => {
    window.open(
      baseUrl +
      "masters/exportDeviceProfile/" + projectId
    );

    return false;
  }


  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", width: "100%", marginTop: "13%" }}>
        <Modal
          visible={editpopup}
          animationType={"slide"}
          transparent={true}
          onRequestClose={() => {
            setEditPopup(false);
          }}
        >
          <View style={isMobile ? {
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 5,
            alignSelf: "center",
            borderWidth: 1,
            // justifyContent: "center",
            height: "25%",
            margin: "auto",
            padding: 10,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "60%",
          } : [styles.contanerSwitch]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setEditPopup(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 0 }}
              />
            </TouchableOpacity>

            <Text style={[styles.tableHead, { marginBottom: 20 }]}>
              Do You Want Update Status
            </Text>
            <View
              style={{
                flexDirection: "row",
                paddingRight: 10,
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setEditPopup(false), toggleSwitch();
                }}
                style={styles.saveBtn}
              >
                <Text style={{ color: "white" }}>Yes</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setEditPopup(false);
                }}
                style={styles.saveBtn}
              >
                <Text style={{ color: "white" }}>No</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>

      <View style={isMobile ? { width: '100%', marginTop:"30%" } : { width: '100%', flexDirection: 'row' }}>
        <View style={isMobile ? {  margin: 10, } : { flex: 1, margin: 10 }}>
          <Text style={isMobile ? [styles.lableText, {marginTop:"-3%" }] : [styles.lableText]}>Profile {">"} Device Profile</Text>
        </View>
        <View style={isMobile ? { alignItems: "center", marginTop: 10, marginLeft:'-35%', width:"170%" } : { alignItems: "center", flex: 2, marginTop: 10 }}>
          {/* <TouchableOpacity onPress={() => exportCsvZoneData()}><Text>Download CSV</Text></TouchableOpacity> */}
          <TextInput
            style={styles.textInputStyle}
            onChangeText={(text) => searchFilterFunction(text)}
            value={searchData}
            underlineColor={"white"}
            theme={{
              colors: {
                text: "black",
                primary: "white",
                background: "transparent",
              },
            }}
            underlineColorAndroid="transparent"
            placeholder="Search Device Profile"
            left={
              <TextInput.Icon
                name={() => <IconEdit name="search" size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              />
            }
          />
        </View>

        <View style={isMobile ? { flex: 1, alignItems: "flex-end" , marginTop:"5%", marginLeft:"25%"} : { flex: 1, alignItems: "flex-end" }}>
          <TouchableOpacity
            style={{
              alignItems: "center",
              backgroundColor: "#006EB9",
              padding: 10,
              marginBottom: 10,
              borderWidth: 1,
              borderColor: "#006EB9", width: '50%'
            }}
            onPress={() => {
              navigation.navigate("CreateDeviceProfile");
            }}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Create
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={isMobile ? { width: '100%', alignItems: "flex-end", marginTop:"-16%", marginLeft:"-120%" } : { width: '100%', alignItems: "flex-end" }}>
        <TouchableOpacity
          style={isMobile ? {
            alignItems: "center",
            backgroundColor: "#006EB9",
            padding: 10,
            borderWidth: 1,
            borderColor: "#006EB9", width: '40%'
          } : {
            alignItems: "center",
            backgroundColor: "#006EB9",
            padding: 10,
            borderWidth: 1,
            borderColor: "#006EB9", width: '10%'
          }}
          onPress={() => {
            downloadExcelReport();
          }}
        >
          <Text style={styles.btnText}>
            <Icon name="exclefile1" size={15} color="#fff" /> Download
          </Text>
        </TouchableOpacity>
      </View>

      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={{ flex: 1 }}>
          {/* <TouchableOpacity
            style={styles.addDevice}
            // onPress={() => isCreatable()}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Country
            </Text>
          </TouchableOpacity> */}
        </View>
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={isMobile ? {
            borderWidth: 1,
            borderColor: "#e3e0e0",
            width: "100%",
            alignSelf: "center",
          } : {
            borderWidth: 1,
            borderColor: "#e3e0e0",
            width: "75%",
            alignSelf: "center",
          }}
        >
          <View>
            <DataTable
              // title="Country"
              columns={columns}
              data={filterData}
              customStyles={customStyles}
              pagination
            />
          </View>
          {/* prvider datatable code here */}

          {/* ended */}
        </View>
        {/* </ScrollView> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  input: {
    borderColor: "gray",
    width: "60%",
    borderWidth: 1,
    borderRadius: 10,

    padding: 10,
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
    color: "#000000",
  },
  addDevice: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "100%",
    flex: 1,
    marginVertical: 10,
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 10,
    margin: 0,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "45%",
    borderRadius: 5,
    color: "#000",
    fontWeight: "400",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
});
