import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  ScrollView,
  Alert,
  ActivityIndicator,
  Switch,
  useWindowDimensions,
  Image,
  Dimensions,
} from "react-native";
import { RadioButton, Button } from "react-native-paper";
import moment from "moment";
import DataTable from "react-data-table-component";

import { TabView, SceneMap, TabBar } from "react-native-tab-view";

import { CheckBox } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { DatePickerModal } from "react-native-paper-dates";
import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";
import IconRefresh from "react-native-vector-icons/FontAwesome";
import IconLoc from "react-native-vector-icons/MaterialIcons";

import SuccessPopup from "../../../components/SuccessPopup";

import Icon from "react-native-vector-icons/AntDesign";
import React, { useState, useRef, useEffect } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, postData } from "../../../networking/api";
import { useFocusEffect } from "@react-navigation/native";

export default function EditSubzoneProfile({ navigation, route }) {
  const { SubZoneId } = route?.params;
  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [animating, setAnimating] = useState(true);
  const [isAdd, setisAdd] = useState(false);

  const [genderchecked, setGenderChecked] = useState(1);
  const [isMarriedchecked, setMaritialChecked] = useState(1);
  const [isstatuschecked, setStatusChecked] = useState(1);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [empCode, setEmpCode] = useState("");

  const [SubZoneID, setSubZoneID] = useState("1");
  const [isSubZoneIDValid, setSubZoneIDValid] = useState(false);
  const [isSubZoneIDEntered, setSubZoneIDEntered] = useState(false);

  const [SubZoneName, setSubZoneName] = useState("");
  const [isSubZoneNameValid, setSubZoneNameValid] = useState(true);
  const [isSubZoneNameEntered, setSubZoneNameEntered] = useState(false);

  const [projectId, setProjectId] = useState(0);

  const [EmailId, setEmailId] = useState("");
  const [isEmailIdValid, setEmailIdValid] = useState(false);
  const [isEmailIdEntered, setEmailIdEntered] = useState(false);

  const [SubzoneCode, setSubzoneCode] = useState("");
  const [isSubzoneCodeValid, setSubzoneCodeValid] = useState(true);
  const [isSubzoneCodeEntered, setSubzoneCodeEntered] = useState(false);

  const [Location, setLocation] = useState("");
  const [isLocationValid, setLocationValid] = useState(false);
  const [isLocationEntered, setLocationEntered] = useState(false);

  const [isZoneIDValid, setZoneIdValid] = useState(false);

  const [ZoneId, setZoneId] = useState([]);
  const [ZoneValue, setZoneValue] = useState(null);
  const [ZoneValueId, setZoneValueId] = useState();

  const [ZoneNameData, setZoneNameData] = useState([]);

  const [isFocus, setIsFocus] = useState(false);

  const dropdownRefZone = useRef();
  const dropdownRefOmegaiD = useRef();

  const closeMenu = () => {
    dropdownRefZone.current.close();
    dropdownRefOmegaiD.current.close();
    //return calls;
    // // console.log("on scroll close in dropdown")
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);
  //Dropdown value pickup
  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [OmegaDetails, setOmegaDetails] = useState([]);

  const [OmegavalueId, setOmegaValueiD] = useState("");
  const [shouldShow, setShouldShow] = useState(true);
  const [OmegaIdValue, setOmegaIdValue] = useState(null);

  //Error message
  const [ZoneIdError, setZoneIdError] = useState("");
  const [OmegaError, setOmegaError] = useState("");

  const [SubZoneIdError, setSubZoneIdError] = useState("");

  const [SubZoneNameError, setSubZoneNameError] = useState("");
  const [SubZoneCodeError, setSubZoneCodeError] = useState("");
  const [SubZoneLocationError, setSubZoneLocationError] = useState("");
  const [AnalogInput, setAnalogInput] = useState([]);
  const [DigitalInput, setDigitalInput] = useState([]);

  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);

  const [Lat, setLatitude] = useState("");
  const [Long, setLongitude] = useState("");

  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  //DOb
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );

  //DOJ
  const [joinDate, setJoinDate] = React.useState<Date | undefined>(undefined);
  const [joinOpen, setJoinOpen] = React.useState(false);

  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  const onDismissJoin = React.useCallback(() => {
    setOpen(false);
  }, [setJoinOpen]);

  const onConfirmJoin = React.useCallback(
    (params) => {
      setJoinOpen(false);
      setJoinDate(params.date);
    },
    [setJoinOpen, setJoinDate]
  );
  //Regex
  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  // var alpha_pattern = new RegExp(/^[a-zA-Z0-9]*$/);
  var alpha_numeric = new RegExp(/^[a-z0-9][a-z0-9 ]{1,48}[a-z0-9]$/i);
  // var alpha_pattern = new RegExp(/^[A-Za-z0-9_@./#&+-]*$/);
  // var alpha_pattern = new RegExp(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/);//THIS

  var alpha_pattern = new RegExp(/^[a-zA-Z0-9!@#$%^&-]+(\s[a-zA-Z0-9!@#$%^&-]+)?$/);

  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(
    /^[a-zA-Z0-9]+$/
    // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/
  );
  // var MobileNo_pattern = new RegExp(/^[0]?[789]\d{9}$/);
  // var number_regex = new RegExp(/^[0-9]*$/);
  const regex = /^[0-9]*$/;

  const DigitalOutputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={OmegaDetails}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );

  const AnalogInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columnsAnalog}
                  data={AnalogInput}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
  const DigitalInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columnDigitalInput}
                  data={DigitalInput}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );

  const renderScene = SceneMap({
    DigitalOutput: DigitalOutputRoute,
    DigitalInput: DigitalInputRoute,
    AnalogInput: AnalogInputRoute,
  });
  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "DigitalOutput", title: "Digital Output" },
    { key: "DigitalInput", title: "Digital Input" },
    { key: "AnalogInput", title: "Analog Input" },
  ]);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      // getOmegaDependentByProjectId();
      // getAllZoneProfileData();
      getSubZoneById();
    }, [])
  );
  //Get Sub Zone  data by id
  const getSubZoneById = async () => {
    setIsLoading(true);
    try {
      var SubZoneData = JSON.stringify({
        module: "sub_zone_master",
        relation: [
          {
            module: "sub_zone_devices",
            subModule: "devices_master",
          },
          {
            module: "zone_master",
          },
        ],
        id: SubZoneId,
      });
      const SubZoneDataById = await postData(
        SubZoneData,
        "/masters/getMasterlist"
      );
      setIsLoading(false);
      var code = SubZoneDataById.code;
      if (code == 1) {
        // console.log("Sub Zone data list api====>", SubZoneDataById.data);
        console.log("SubZoneDataById.data[0]", SubZoneDataById.data[0].zone_master.zone_name);

        setZoneValue(SubZoneDataById.data[0].zone_master.zone_name);
        setSubZoneName(SubZoneDataById.data[0].sub_zone_name);
        setSubzoneCode(SubZoneDataById.data[0].sub_zone_code);
        setStatusChecked(SubZoneDataById.data[0].active);
        getOmegaDependentByProjectId(SubZoneDataById.data[0].project_id);
        getOmegaDetails(SubZoneDataById.data[0].sub_zone_devices[0].device_id);
        setOmegaValueiD(SubZoneDataById.data[0].sub_zone_devices[0].device_id);
        setSubZoneID(SubZoneDataById.data[0].sub_zone_devices[0].id);
        setLongitude(
          SubZoneDataById.data[0].sub_zone_devices[0].devices_master.lat
        );
        setLatitude(
          SubZoneDataById.data[0].sub_zone_devices[0].devices_master.long
        );
        setOmegaIdValue(
          SubZoneDataById.data[0].sub_zone_devices[0].devices_master.device_name
        );
      } else {
        setIsLoading(false);
        //alert(SubZoneDataById.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");
    if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse==>", userDetailsresponse);

      setEmpCode(userDetailsresponse.employee_code);
      setProjectId(userDetailsresponse.project_selected);
    }
    if (projValue != null) {
      getAllZoneProfileData(projValue);
    } else if (jsonValue != null) {
      getAllZoneProfileData(userDetailsresponse.project_selected);
    }
  };

  // DATATABLE subzone TYPE COLUMN AND STYLE
  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const columns = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];
  const columnsAnalog = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];

  const columnDigitalInput = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];
  //Get details when click on omega id
  const getOmegaDetails = async (deviceId) => {
    setIsLoading(true);
    try {
      var OmegaDetails = JSON.stringify({
        device_id: deviceId,
      });
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "masters/getOmegaDetails"
      );
      setIsLoading(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {
        // console.log("Omega details api====>", OmegaDetailsResponse.data);
        // setShouldShow(false)
        setOmegaDetails(OmegaDetailsResponse.data[0].valves);
        setAnalogInput(OmegaDetailsResponse.data[0].analogInput);
        setDigitalInput(OmegaDetailsResponse.data[0].digitalInput);
      } else {
        setIsLoading(false);
        //alert(OmegaDetailsResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const getOmegaDependentByProjectId = async (projectID) => {
    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        project_id: projectID,
      });
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/getUniqueOmegaIds"
        // "masters/getOmegaFromBermad"
      );
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log("SUBZONE Omega list api====>", OmegaIdList.data);
        var mergeData = OmegaIdList.data;
        mergeData.unshift({
          id: 0,
          omega_id: "",
          device_name: "None",
          lat: "",
          long: "",

        });
        setOmegaIdData(mergeData);
        // setOmegaIdData(OmegaIdList.data);
      } else {
        setIsLoading(false);
        var mergeData = OmegaIdList.data;
        mergeData.unshift({
          id: 0,
          omega_id: "",
          device_name: "None",
          lat: "",
          long: "",

        });
        setOmegaIdData(mergeData);
        //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  //Get Omega  id from Bermad when click on refresh button
  const getOmegaIdList = async () => {
    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        module: "devices_master",
        relation: [],
      });
      // console.log("OmegaIdData", OmegaIdData);
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/getMasterList"
        // "masters/getOmegaFromBermad"
      );
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log("Omega list api====>", OmegaIdList.data);

        setOmegaIdData(OmegaIdList.data);
      } else {
        setIsLoading(false);
        //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const renderOmegaIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {OmegaIdData.length > 0 && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {/* {isAdd == false && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )} */}
        {item.id === OmegavalueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderZoneIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.zone_name}</Text>}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.zone_name}</Text>
        )}
        {item.id === ZoneValueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const CheckEnteredData = async () => {
    setSubZoneNameEntered(true);
    setSubzoneCodeEntered(true);

    // console.log("check nenter data");

    //setOmegaId(OmegaIds);
    if (
      isSubZoneNameValid
      // &&
      // isSubzoneCodeValid
    ) {
      // console.log("success");
      updateSubZoneProfileData();
      // setZoneIdError("");
      // setSubZoneIdError("");
      setSubZoneNameError("");
      // setSubZoneCodeError("");
    } else {
      // console.log("error ELSE", isSubZoneNameValid, isSubzoneCodeValid);
      if (ZoneValue == "") {
        setZoneIdError("Please enter zone id");
      }
      if (SubZoneName == "") {
        setSubZoneNameError("Please enter zone name");
      }
      // subzone id
      // if (SubZoneID == "") {
      //     setSubZoneIdError("Please enter sub zone ID");
      // }
      //subzone code
      // if (SubzoneCode == "") {
      //     setSubZoneCodeError("Please enter zone code ");
      // }
    }
  };
  // Get all Zone list Api
  const getAllZoneProfileData = async (pid) => {
    setIsLoading(true);
    try {
      var getZoneData = JSON.stringify({
        module: "zone_master",
        relation: [],
        condition: {
          project_id: pid,
        },
      });
      const ZoneNameResponse = await postData(
        getZoneData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = ZoneNameResponse.code;
      if (code == 1) {
        // console.log("ZoneNameResponse list====>", ZoneNameResponse.data);

        setZoneNameData(ZoneNameResponse.data);
      } else {
        setIsLoading(false);
        //alert(ZoneNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //SubZone Profile update Api

  const updateSubZoneProfileData = async () => {
    setIsLoading(true);
    try {
      var ZoneMasterData = JSON.stringify({
        id: SubZoneId,
        //module: "sub_zone_master",
        modelName: "sub_zone_master",
        // data: {
        //   zone_id: ZoneValueId,
        //   sub_zone_code: SubzoneCode,
        //   sub_zone_name: SubZoneName,
        //   active: isstatuschecked,
        // },
        inputData: {
          project_id: projectId,
          sub_zone_name: SubZoneName,
          sub_zone_code: SubzoneCode,
          zone_id: ZoneValueId,
          active: isstatuschecked,
      },
      relation: {
        subModelName: "sub_zone_devices",
        subModelInputData: [
            {
                id: SubZoneID,
                device_id: OmegavalueId,
                project_id: projectId,
                active: 1,
            },
        ],
      },
        // submodule: "sub_zone_devices",
        // submoduledata: [
        //   {
        //     id: SubZoneID,
        //     data: {
        //       project_id: projectId,
        //       device_id: OmegavalueId,
        //       active: 1,
        //     },
        //   },
        // ],
      });
      // console.log("Zone payload UPDATE===", ZoneMasterData);
      const SubZoneProfileResponse = await postData(
        ZoneMasterData,
        "masters/profileCreationAndUpdation"
      );

      setIsLoading(false);

      var code = SubZoneProfileResponse.code;
      if (code == 1) {
        setPopupMessage("Record Updated Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        // setIsVisible(false);
        // console.log("Zone profile update", SubZoneProfileResponse.data);
        navigation.navigate("SubzoneProfile");
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(SubZoneProfileResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  return (
    <View style={styles.container}>
      <View style={isMobile ? { flexDirection: "row", width: "100%", marginTop: "15%" } : { flexDirection: "row", width: "100%", marginTop: "3%" }}>
        {" "}
        <View style={{ flex: 4, margin: 10 }}>
          <Text style={isMobile ? [styles.lableText, { marginTop: -15 }] : styles.lableText}>
            Profile {">"}{" "}
            <Text onPress={() => navigation.navigate("SubzoneProfile")}>
              {" "}
              Sub Zone/Group{" "}
            </Text>{" "}
            {">"} Edit Sub Zone/Group
          </Text>
        </View>
        {/* <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => setIsVisible(true)}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Users
            </Text>
          </TouchableOpacity>
        </View> */}
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 45,
              paddingHorizontal: 25,
            },
          ]}
        >
          <View
            style={isMobile ? {
              // flex: 1,
              justifyContent: "center",
              alignItems: "center",
              // flexDirection: "row",
            } : {
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View style={{ flex: 1, marginRight: 20, marginBottom: "0%" }}>
              <View
                style={{
                  // backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                }}
              >
                <View style={isMobile ? { marginLeft: -15, marginTop: -60 } : null}>
                  <Text style={styles.lableText}>Zone Id</Text>
                  <View>
                    <Dropdown
                      ref={dropdownRefZone}
                      style={isMobile ? {
                        borderColor: "#c3c3c3",
                        width: "120%",
                        borderWidth: 1,
                        borderRadius: 2,
                        height: 40,
                        outlineStyle: "none",
                        padding: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      } : styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={ZoneNameData}
                      search
                      maxHeight={300}
                      labelField="zone_name"
                      valueField="zone_name"
                      placeholder={!isFocus ? "Select Zone Id" : "...."}
                      searchPlaceholder="Search..."
                      value={ZoneValue}
                      onChange={(item) => {
                        console.log("eee", item);

                        setZoneValue(item.zone_name);
                        setZoneValueId(item.id);
                        setZoneIdError("");
                        // console.log("zone id value---", item.id);
                      }}
                      renderItem={renderZoneIdListItems}
                    />
                    <Text style={[styles.error_text]}>{ZoneIdError}</Text>
                  </View>
                </View>

                <View style={isMobile ? { marginLeft: -15,} : null}>
                  <Text style={isMobile ? [styles.lableText , {marginTop:-10}] : styles.lableText}> Sub Zone Name</Text>
                  <TextInput
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "120%",
                      borderWidth: 1,
                      borderRadius: 4,
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                    } : styles.input}
                    placeholder="Enter SubZone Name"
                    value={SubZoneName}
                    onChangeText={(value) => {
                      setSubZoneNameValid(false);
                      setSubZoneName(value);
                      if (value.length == 0) {
                        setSubZoneNameError("Please enter subzone name");
                        // console.log(" enter");
                      } 
                      // else if (!alpha_pattern.test(value)) {
                      //   setSubZoneNameError("Please enter valid character only");
                      //   // console.log("error charcter");
                      // } 
                      else {
                        setSubZoneNameValid(true);

                        setSubZoneNameError("");
                      }
                    }}
                  />

                  <Text style={[styles.error_text]}>{SubZoneNameError}</Text>
                </View>

                <View style={isMobile ? { marginLeft: -15,} : null}>
                <Text style={isMobile ? [styles.lableText , {marginTop:-10}] : styles.lableText}>Omega name</Text>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ width: "100%" }}>
                    <Dropdown
                      ref={dropdownRefOmegaiD}
                      style={isMobile ? {
                        borderColor: "#c3c3c3",
                        width: "120%",
                        borderWidth: 1,
                        borderRadius: 2,
                        height: 40,
                        outlineStyle: "none",
                        padding: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      } : styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={OmegaIdData}
                      search
                      maxHeight={300}
                      labelField="device_name"
                      valueField="device_name"
                      placeholder={
                        OmegaIdValue ? OmegaIdValue : "Select Omega name"
                      }
                      searchPlaceholder="Search..."
                      value={OmegaIdValue}
                      onChange={(item) => {
                        setOmegaIdValue(item.device_name);
                        setOmegaValueiD(item.id);
                        setOmegaError("");
                        getOmegaDetails(item.id);
                        // console.log("omega id value---", item.id);
                      }}
                      renderItem={renderOmegaIdListItems}
                    />
                    <Text style={[styles.error_text]}>{OmegaError}</Text>
                  </View>
                  {/* <View style={{ marginLeft: -100 }}>
                                        {isLoading ? (
                                            <ActivityIndicator
                                                //visibility of Overlay Loading Spinner
                                                color="#1DA1F2"
                                                visible={isLoading}
                                                //Text with the Spinner
                                                textContent={"Loading..."}
                                                size={40}
                                            //  //Text style of the Spinner Text
                                            //  textStyle={styles.spinnerTextStyle}
                                            />
                                        ) : (
                                            <IconRefresh name="refresh" size={30} onPress={() => getOmegaIdList()} />
                                        )}
                                    </View> */}
                </View>
                </View>
                
              </View>
            </View>
            <View
              style={ isMobile ? {
                // flex: 1,
                marginBottom: "2%",
              } : {
                flex: 1,
                marginBottom: "2%",
              }}
            >
              <View style={isMobile ? {marginTop:-25} : null}>
                {/* <View> */}
                {/* <Text style={[styles.error_text]}>{SubZoneNameError}</Text> */}
                {/* </View> */}
                <Text style={isMobile ? [styles.lableText,{marginTop:5}] : styles.lableText}> Sub Zone Code</Text>
                <TextInput
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 4,
                    padding: 10,
                    marginTop: 4,
                    marginBottom: 6,
                    backgroundColor: "#DEDEDE" 
                  } : [styles.input, { backgroundColor: "#DEDEDE" }]}
                  placeholder="Enter SubZone Code"
                  value={SubzoneCode}
                  editable={false}
                  maxLength={10}
                  keyboardType={"numeric"}
                  onChangeText={(value) => {
                    setSubzoneCodeValid(false);
                    setSubzoneCode(value);
                    if (value.length == 0) {
                      setSubZoneCodeError("Please enter Sub zone code");
                    } else if (!regex.test(value)) {
                      setSubZoneCodeError("Please enter number only");
                    }
                    // else if (value.length > 0 && value.length <= 9) {
                    //   setSubZoneCodeError('Please enter 10 digit number')
                    // }
                    else {
                      setSubzoneCodeValid(true);
                      setSubZoneCodeError("");
                    }
                  }}
                />
                <Text style={[styles.error_text]}>{SubZoneCodeError}</Text>
              </View>

              <Text style={isMobile ? [styles.lableText,{marginTop:-5}] : styles.lableText}>Location </Text>
              <View style={{ flexDirection: "row" }}>
                <TextInput
                  placeholder="Latitude"
                  style={[styles.inputLocation, { backgroundColor: "#DEDEDE" }]}
                  placeholder="Lat"
                  maxLength={10}
                  editable={false}
                  keyboardType={"numeric"}
                  value={Lat}
                />

                <TextInput
                  placeholder="Longitude"
                  style={[styles.inputLocation, { backgroundColor: "#DEDEDE" }]}
                  placeholder="Long"
                  maxLength={10}
                  keyboardType={"numeric"}
                  editable={false}
                  keyboardType={"numeric"}
                  value={Long}
                />
                <IconLoc name="my-location" size={20} style={{ margin: 10 }} />
              </View>
              <Text style={[styles.error_text]}>{SubZoneLocationError}</Text>

              <View>
                <Text style={isMobile ? [styles.lableText,{marginTop:-10}] : styles.lableText}>Status </Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={[styles.statusNames, { color: "#05D6B3" }]}>
                    Active
                  </Text>
                  <RadioButton
                    value={isstatuschecked}
                    status={isstatuschecked === 1 ? "checked" : "unchecked"}
                    onPress={() => setStatusChecked(1)}
                  />
                  <Text style={[styles.statusNames, { color: "#E11A1A" }]}>
                    InActive
                  </Text>
                  <RadioButton
                    value={isstatuschecked}
                    status={isstatuschecked === 0 ? "checked" : "unchecked"}
                    onPress={() => setStatusChecked(0)}
                  />{" "}
                </View>
              </View>
            </View>
          </View>

          <View style={isMobile ? { marginTop: 20, width: "115%", alignSelf: "center", marginBottom:10 } : { marginTop: 20, width: "40%", alignSelf: "center" }}>
            <TabView
              navigationState={{ index, routes }}
              renderScene={renderScene}
              onIndexChange={setIndex}
              initialLayout={{ width: layout.width }}
              // renderLabel={({ route, color }) => (
              //   <Text style={{ color: 'black', margin: 8 }}>
              //     {route.title}
              //   </Text>
              // )}
              renderTabBar={(props) => (
                <TabBar
                  {...props}
                  indicatorStyle={isMobile ? { backgroundColor: "#2c7bbe", height: 70 } : { backgroundColor: "#2c7bbe", height: 50 }}
                  renderLabel={({ route, color }) => (
                    <Text style={isMobile ? { color: "Black", margin: 8 } : { color: "Black", margin: 8 }}>
                      {route.title}
                    </Text>
                  )}
                  style={{ backgroundColor: "#F6F9F9" }}
                />
              )}

            // <-- add this line
            />
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginHorizontal: 20,
            }}
          >
            <TouchableOpacity
              style={isMobile ? {
                alignItems: "center",
                backgroundColor: "#006EB9",
                padding: 10,
                borderWidth: 1,
                borderColor: "#006EB9",
                width: "35%",
                marginRight: 10,
              } : styles.saveBtn}
              onPress={() => CheckEnteredData()}
            >
              <Text style={styles.btnText}>Update</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={isMobile ? {
                alignItems: "center",
                backgroundColor: "#006EB9",
                padding: 10,
                borderWidth: 1,
                borderColor: "#006EB9",
                width: "35%",
                marginRight: 10,
              } : styles.saveBtn}
              onPress={() => navigation.navigate("SubzoneProfile")}
            >
              <Text style={styles.btnText}>Cancel</Text>
            </TouchableOpacity>
          </View>

          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 150,
  },
  genderNames: {
    margin: 7,
  },
  statusNames: {
    margin: 7,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "100%",
    marginRight: "10%",
    marginTop: "0%",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "70%",
  },
  inputLocation: {
    borderColor: "#c3c3c3",
    width: "40%",
    borderWidth: 1,
    borderRadius: 4,
    marginRight: 5,
    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  input: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 4,

    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "20%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  datePicker: {
    justifyContent: "center",
    alignItems: "flex-start",
    width: 320,
    height: 260,
    display: "flex",
  },
  card: {
    // backgroundColor: "white",
    // borderRadius: 8,
    // paddingVertical: 45,
    // paddingHorizontal: 25,
    // width: "100%",
    // flex: 1,
    // marginVertical: 10,
    // paddingBottom: "48%",

    width: "100%",
    flex: 1,
    marginVertical: 0,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 17,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
});
